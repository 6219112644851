  /*
   ** JS functions to let user scroll through horizontal content
   */
   import Vue from 'vue'
   Vue.mixin({
      methods: {
          handleScroll(event) {
              var position = window.scrollY;
              if (position < 0) {
                  position = 0;
              }
              if (this.lastPos <= 0) {
                  this.lastPos = position;
              }
              if (position > (this.lastPos + 100)) {
                  this.lastPos = position;
                  this.$root.scroll = true;
              } else if (position < (this.lastPos - 100)) {
                  this.lastPos = position;
                  this.$root.scroll = false;
              }
              if (position > 20) {
                  this.$root.notontop = true;
              } else {
                  this.$root.notontop = false;
              }
          },
          switchScroll(todo) {
            if (todo === 'off') {
                document.documentElement.classList.add('scroll-off');
                document.body.classList.add('scroll-off');
            } else {
                document.body.classList.remove('scroll-off');
                document.documentElement.classList.remove('scroll-off');
            }
        },
    },
    created: function() {
      window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
  },
})