export default (context, inject) => {
    let seo = { 
        'home': {
            'title' : 'Spannende seksfilms & Hete Seksverhalen', 
            'description' : 'Kies Secret Circle voor jouw portie eigentijdse Seks. De spannendste Seksfilms met de mooiste modellen. Ontdek alle spannende seksverhalen gratis.',
            'keywords' : 'Secret Circle, seksfilms, Sexy, Modern, Spannend, Join to Play',
        },
        'direct': {
            'title' : 'Unlimited',
            'slug' : 'unlimited',
            'description' : 'Kijk goedkoop en onbeberkt seksfilms met Secret Circle Direct.',
            'keywords' : 'onbeperkt',
        },
        'seksfilms': {
            'title' : 'Seksfilms',
            'slug' : 'seksfilms',
            'description' : 'Bekijk geile seksfilms met Kim Holland en haar Hollandse meiden. Elke dag nieuwe sex en wekelijks een kersverse gratis sexfilm. ',
            'keywords' : 'seksfilms, Meiden van Holland seksfilms, sexfilm, sex films, gratis seksfilms, neukfilm',
        },
        'categorieen': {
            'title' : 'Categorieën',
            'slug' : 'categorieen',
            'description' : 'Sex in allerlei geile smaken zie je op Meiden van Holland. Met onze sexfilm categorieën worden alle geile fantasieën vervuld.',
            'keywords' : 'Meiden van Holland categorieen ',
        },
        'shows': {
            'title' : "TV Shows",
            'slug' : 'tv-shows',
            'description' : "Bekijk gratis sex programma's. Van klassiekers uit de doos met Don en Ad tot aftrekinstructies van je favoriete modellen.",
            'keywords' : 'gratis sex, Don en Ad, Handyman',
        },
        'epg': {
            'title' : "TV-gids",
            'slug' : 'tv-gids',
            'description' : "Nu te zien op televisie bij Secret Circle",
            'keywords' : 'tvgids, epg, tv, televisie',
        },
        'modellen': {
            'title' : 'Modellen',
            'slug' : 'modellen',
            'description' : 'Geile Hollandse amateur meiden en pornosterren spelen in de lekkerste seksfilms. Kim Holland en haar vriendinnen neuken voor jou in de lekkerste standjes',
            'keywords' : 'geile meiden, sex actrices, pornosterren, Hollandse meiden',
        },
        'webcamsex': {
            'title' : 'Webcams',
            'slug' : 'webcams',
            'description' : 'Geile webcamsex met 100% Nederlandse dames die live op jou zitten te wachten op Secret Circle. Sexchat hier met een verzameling aan hete, rijpe en exotische meiden.',
            'keywords' : 'Webcamsex, seksfilms, Geile meiden, sexchat, webcams',
        },
        'sexdating': {
            'title' : 'Sexdating',
            'slug' : 'https://www.clubstout.nl/landers/1C57BFD496C0426D0D509C571389271876AB9274D5E244C796BF1F17DCE83812?pi=mvh-menu',
            'description' : 'Lekker daten met echte vrouwen voor echte sex.',
            'keywords' : 'Sexdating',
        }
    }
    inject('seo', seo)
}