import { queries } from '~/helpers/defaultQueries';

export const state = () => ({
  pages: {
    homepage: null,
    seksfilms: null,
    tvshows: null,
    modellen: null,
    webcams: null,
    tvgids: null,
    infoaanmelden: null,
    infoover: null,
    infojouwprovider: null,
    infokijkgratis:null,
    infoveelgesteldevragen: null,
    infovoorwaarden: null,
    infoprivacyverklaring: null,
    infonieuwsbrief: null,
    infofeedback: null,
    infocontact: null
  },  
});

export const actions = {
  async getPage({ commit }, slug) {
    try {
      const response = await this.$axios.get(`${process.env.API_URL}${process.env.API_PREFIX}/resource/${slug}/nl`);
      commit('setPage', response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

export const getters = {
  pages(state) {
    return state.pages;
  },
};

export const mutations = {
  setPage(state, response) {    
    const slug = response.slug.replace(/-/g, '');
    if (!state.pages[slug]) {
      state.pages[slug] = {};
    }
    state.pages[slug] = response;
  },
};
