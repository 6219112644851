export const strict = false;

export const state = () => ({
  loading: false,
  subscriptions: [],
  subscription: false,
  subscription_detail: false,
  creditbundles: [],
  creditbundle: false,
  product_to_pay: false,
  coupon: null,
  queries: {
    subscriptions: {
      filters: [{
        status: 'published'
      }],
      sort: {
        published_at: 'desc'
      },
      include: [{
        benefits: {},
        details: {}
      }]
    },
    creditbundles: {
      filters: [{
        status: 'published'
      }],
      include: [{
        benefits: {},
        details: {}
      }]
    }
  }
})

export const actions = {
  async getSubscriptions({commit}, query) {
    await this.$axios.get(process.env.API_URL + process.env.API_PREFIX + '/subscriptions?query=' + objectToJsonUrl(query))
    .then(response => { 
      commit('setSubscriptions', response.data); 
    }).catch((error) => {
      console.log(error);
      commit('setLoading', 'error');
    }); 
  },
  async getCreditbundles({commit}, query) {
    await this.$axios.get(process.env.API_URL + process.env.API_PREFIX + '/credit-bundles?query=' + objectToJsonUrl(query))
    .then(response => { 
      commit('setCreditbundles', response.data); 
    }).catch((error) => {
      console.log(error);
      commit('setLoading', 'error');
    }); 
  },
  rentByCredits({commit, rootState, state}, resource_id){
    commit('setLoading', true);
    const subQuery = {
      resource_id: `${resource_id}`,
    };
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/user-subscription/rent-by-credits', subQuery)
    .then(response => {
      commit('resourcesStore/setAccess', {
        access: true,
        is_free: false,
        subscription: response.data.data
      }, {root: true});
    }).catch((error) => {
      console.log(error);
      this.$sentry.captureException(error, {
        tags: { 
          rentByCredits: true,
          user_id: rootState.auth.loggedIn ? rootState.auth.user.user.id : false
        } 
      });
      commit('setLoading', false);
      var alertObj = {};
      if (error.response) {
        console.log('Error status:', error.response.status);
        console.log('Error data:', error.response.data);   
        console.log('Error headers:', error.response.headers); 
        alertObj.type = 'error';
        alertObj.message = 'Er is iets mis gegaan. Je hebt geen toegang. ' + ' Error status:' + error.response.status + ' Error message:' + error.message + ' Error data: ' + error.response.data.data;
        return commit('notificationStore/setNotification', alertObj, { root: true });    
      }
      alertObj.type = 'error';
      alertObj.message = 'Er is iets mis gegaan. Je hebt geen toegang.';
      commit('notificationStore/setNotification', alertObj, { root: true });    
    });

  },

  cancelIncasso({commit, rootState, state}, user_subscription_id){
    commit('setLoading', true);
    const query = {
      user_subscription_id: `${user_subscription_id}`,
    };
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/user-subscription/cancel-recurring', query)
    .then(response => {
      this.$auth.fetchUser();
      commit('setLoading', false);
      var alertObj = {};
      alertObj.type = 'info';
      alertObj.message = 'Je incasso is opgezegd. We zullen geen betalingen meer incasseren.';
      commit('notificationStore/setNotification', alertObj, { root: true });
    }).catch((error) => {
      console.log(error);
      this.$sentry.captureException(error, {
        tags: { 
          cancelIncasso: true,
          user_id: rootState.auth.loggedIn ? rootState.auth.user.user.id : false
        } 
      });
      commit('setLoading', false);
      var alertObj = {};
      alertObj.type = 'error';
      alertObj.message = 'Er is iets mis gegaan. Je incasso is niet opgezegd.';
      commit('notificationStore/setNotification', alertObj, { root: true });    
    });

  },

  reactivateIncasso({commit, rootState, state}, user_subscription_id){
    commit('setLoading', true);
    const query = {
      user_subscription_id: `${user_subscription_id}`,
    };
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/user-subscription/reactive-recurring', query)
    .then(response => {
      this.$auth.fetchUser();
      commit('setLoading', false);
      var alertObj = {};
      alertObj.type = 'info';
      alertObj.message = 'Je incasso is weer actief. Welkom terug!';
      commit('notificationStore/setNotification', alertObj, { root: true });
    }).catch((error) => {
      console.log(error);
      this.$sentry.captureException(error, {
        tags: { 
          reactivateIncasso: true,
          user_id: rootState.auth.loggedIn ? rootState.auth.user.user.id : false
        } 
      });
      commit('setLoading', false);
      var alertObj = {};
      alertObj.type = 'error';
      alertObj.message = 'Er is iets mis gegaan. Je hebt geen toegang.';
      commit('notificationStore/setNotification', alertObj, { root: true });    
    });

  },

}

export const mutations = {
  setSubscriptions(state, data) {
    state.subscriptions = data;
  },
  setCreditbundles(state, data) {
    state.creditbundles = data;
  },
  setSubscription(state, subscription) {
    state.subscription = subscription
  },
  setSubscriptionDetail(state, detail) {
    state.subscription_detail = detail;
  },
  setCreditbundle(state, bundle) {
    state.creditbundle = bundle
  },
  setProductToPay(state, product) {
    state.product_to_pay = product;
  },
  setCouponDiscount(state, couponData) {
    state.coupon = {
      new_price: couponData.price,
      discount: (state.product_to_pay.price - couponData.price),
      active: true
    }
  },
  setResetCouponSub(state){
    state.coupon = null;
  },
  setLoading(state, todo){
    state.loading = todo;
  },
  setPaywallToSubscription(state, slug) {
    state.subscriptions.data.forEach(function(subscription) {
      if (subscription.slug === slug) {
        state.subscription = subscription;
      }
    })
  },
}

const jsonToUrlValue = (value) => {
  if (typeof value === 'number' || typeof value === 'boolean' || value === null) {
    return String(value);
  } else if (typeof value === 'string') {
        // Quote the string if necessary
    if (['true', 'false', 'null'].includes(value) || !isNaN(Number(value))) {
      return `'${value}'`;
    }
    return value.replace(/ /g, '+');
  } else if (Array.isArray(value)) {
    return `(${value.map(jsonToUrlValue).join(',')})`;
  } else if (typeof value === 'object') {
    const entries = Object.entries(value).map(([key, val]) => `${key}:${jsonToUrlValue(val)}`);
    return `(${entries.join(',')})`;
  }
    // Fallback for undefined or functions
  return '';
};

const objectToJsonUrl = (obj) => {
  return encodeURIComponent(jsonToUrlValue(obj));
};