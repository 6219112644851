export const serialize = {
    serializeFilter(obj) {
        var str = "";
        for (var key in obj) {
            str += "&filter[";
            str += key + "]=" + encodeURIComponent(obj[key]);
        }
        return str;
    },
    serializeSort(obj) {
        var str = "";
        for (var key in obj) {
            str += "&sort[";
            str += key + "]=" + encodeURIComponent(obj[key]);
        }
        return str;
    }
}
export const serializeCam = {
    serializeCam(obj) {
        var str = "";
        for (var key in obj) {
            if (str != "") {
                str += "&";
            }
            if (key == 'limit') {
                str += 'limit=' + obj[key];
            } else if (key == 'leeftijd') {
                str += 'where[leeftijd][between]=' + obj[key];
            } else if (key == 'orderby') {
                if (obj[key] == 'waarderingdesc') {
                    str += 'orderby[waardering]=desc';
                }
                if (obj[key] == 'leeftijdasc') {
                    str += 'orderby[leeftijd]=asc';
                }
                if (obj[key] == 'leeftijddesc') {
                    str += 'orderby[leeftijd]=desc';
                }
            } else if (key == 'page') {
                str += 'page=' + obj[key];
            } else if (key == 'modelnaam') {
                console.log(key);
                console.log(obj[key]);
                if (obj[key] != false) {
                    str += 'where[' + key + '][like]=' + obj[key];
                } else {
                    str += '';
                }
                console.log(str);
            } else {
                str += 'where[' + key + ']=' + obj[key];
            }
        }
        return str;
    }
}