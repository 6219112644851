import { format } from 'date-fns'

export function formatDate(value) {
  const date = new Date(value);
  if (value && !isNaN(date)) {
    return format(date, 'dd/MM/yy');
  }
  return 'Invalid date';
}

export function formatEpgDate(value) {
  if (value) {
    return format(new Date(value), 'HH:mm');
  }
}

export function formatRentDate(value) {
  if (value) {
    return format(new Date(value), 'dd/MM/yy - kk:mm');
  }
}

export function convertDuration(value) {
  const minutes = value / 60;
  return Math.ceil(minutes);
}

export function slugToTitle(value) {
  if (value) {
    var str = value.replace(/-/g, ' ');
    var str2 = str.replace(/\\|\//g,'');
    return str2.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  }
}

export function escapeHtmlEntities(value) {
  if (value) {
    return value.replace(/&/g, '&amp;').replace(/\+/g, '%2B').replace(/\s/g, '+');
  }
}
