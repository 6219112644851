import 'core-js/features/object/entries'
import 'core-js/features/array/includes'
import 'core-js/features/array/find'
import 'core-js/features/array/from'
import 'core-js/features/object/assign'
import 'core-js/es/promise'
import 'core-js/es/symbol'
import 'core-js/es/promise'
import 'core-js/es/symbol'

(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('append')) {
      return;
    }
    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append() {
        var argArr = Array.prototype.slice.call(arguments),
        docFrag = document.createDocumentFragment();
        
        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });
        
        this.appendChild(docFrag);
      }
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
